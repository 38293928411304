/**
 * Seeq REST API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 66.0.0-v202408262255-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AxiosPromise } from 'axios';
import { getAxiosInstance, SeeqAxiosRequestConfig } from '@/requests/axios.utilities';
import { isNil, omitBy } from 'lodash';
import * as qs from 'qs';
import * as models from '../model/models';
import { APPSERVER_API_PREFIX } from '@/main/app.constants';

export class sqConditionMonitorsApiClass {
 
   constructor() {}

  /**
   * @summary Archive a condition monitor
   * @param {string} id - ID of the condition monitor to archive.
   */
  public archiveConditionMonitor(
    {
      id
    } : {
      id: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return getAxiosInstance()({
      method: 'delete',
      url: APPSERVER_API_PREFIX + `/condition-monitors/${encodeURIComponent(String(id))}`,

      ...extraHttpRequestConfig
      }) as AxiosPromise;
  }

  /**
   * @summary Create a new condition monitor
   */
  public createConditionMonitor(
    body: models.ConditionMonitorInputV1,
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/condition-monitors`,
      data: body,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.ConditionMonitorOutputV1>;
  }

  /**
   * @summary Get a condition monitor
   * @param {string} id - The Seeq ID for the condition monitor
   */
  public getConditionMonitor(
    {
      id
    } : {
      id: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/condition-monitors/${encodeURIComponent(String(id))}`,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.ConditionMonitorOutputV1>;
  }

  /**
   * @summary Get a list of condition monitors
   * @param {Array<string>} [executorIds] - Filter condition monitors to only those created by the specified user IDs
   * @param {string} [nameSearch] - Filter the condition monitors to those whose name match any part of the specified string
   * @param {Array<string>} [conditionIds] - Filter condition monitors to only those that are monitoring one of the specified conditions as specified by the list of IDs
   * @param {boolean} [enabledFilter] - Filter condition monitors by their enabled status
   * @param {number} [offset=0] - Whether to filter condition monitors to only users who are enabled.
   * @param {number} [limit=40] - The pagination limit, the total number of condition monitor that will be returned in this page of results
   * @param {string} [sortField=Name] - Orders the condition monitors by either name, createdAt or workbook
   * @param {boolean} [sortAsc=true] - Sorts the sort field in ascending order when true and descending when false
   */
  public getConditionMonitors(
    {
      executorIds,
      nameSearch,
      conditionIds,
      enabledFilter,
      offset,
      limit,
      sortField,
      sortAsc
    } : {
      executorIds?: Array<string>,
      nameSearch?: string,
      conditionIds?: Array<string>,
      enabledFilter?: boolean,
      offset?: number,
      limit?: number,
      sortField?: SortFieldEnum,
      sortAsc?: boolean
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/condition-monitors`,

      params: omitBy({
        ['executorIds']: executorIds,
        ['nameSearch']: nameSearch,
        ['conditionIds']: conditionIds,
        ['enabledFilter']: enabledFilter,
        ['offset']: offset,
        ['limit']: limit,
        ['sortField']: sortField,
        ['sortAsc']: sortAsc
      }, isNil),
      paramsSerializer: params => qs.stringify(params, { indices: false }),
      ...extraHttpRequestConfig
      }) as AxiosPromise<models.GetConditionMonitorItemsOutputV1>;
  }

  /**
   * @summary Update a condition monitor
   * @param {string} id - The Seeq ID for the condition monitor
   */
  public updateConditionMonitor(
    body: models.ConditionMonitorInputV1,
    {
      id
    } : {
      id: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/condition-monitors/${encodeURIComponent(String(id))}`,
      data: body,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.ConditionMonitorOutputV1>;
  }

}

export enum SortFieldEnum {
    Name = 'Name' as any,
    CreatedAt = 'CreatedAt' as any
}

export const sqConditionMonitorsApi = new sqConditionMonitorsApiClass();
